import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';

import FormTitle from '../FormTitle';
import EditDeleteIcons from '../EditDeleteIcons';
import SeeMoreCard from '../SeeMoreCard';
import { studentActions } from '../../../redux/actions';
import useListPublicEntity from '../../../hooks/useListPublicEntity';
import { showOptions, getId } from '../helpers';
import { dates } from '../../../utils';
import HSpacer from '../../layout/HSpacer';

import { ButtonPrimary, DatePicker } from '../../common';
import {
  Column,
  Input,
  Label,
  InputColumn,
  FullFlexCont,
  WhiteSpace,
  ContactForm,
  BlueInput,
  SubLabel,
  EditDeleteIconsCont,
  Error,
  MobilePadding,
  Select,
} from '../formStyledComponents';

const CurrentStudy = (props) => {
  const {
    profile, littleDevice, name, city,
    address, cp, region, campus, iniDate,
    endDate, gradDate, institutionName,
    tutor, lastname, email, phone, scrollToTop,
    universityName,
  } = props;
  const currentStudyEnded = _get(profile, 'currentStudy.ended', false);
  const initialCountry = _get(profile, 'currentStudy.country.nameLang.es', '');
  const dispatch = useDispatch();

  const {
    register, handleSubmit, errors, reset, watch, setValue, control,
  } = useForm();
  const watchFields = watch(['iniDate', 'endDate', 'gradDate', 'country']);

  const { data: residences } = useListPublicEntity('residence');

  /* handle errors  */
  const nameError = errors.name && <Error>Por favor, indica el título de los estudios en curso</Error>;
  const institutionError = errors.institutionName && <Error>Por favor, indica el nombre de la institución</Error>;
  const countryError = errors.country && <Error>Por favor, indica el país</Error>;
  const cityError = errors.city && <Error>Por favor, indica la ciudad</Error>;
  const endDateError = errors.endDate && !watchFields.endDate && <Error>Debes indicar la fecha de finalización</Error>;
  const gradDateError = errors.gradDate && <Error>Debes indicar la fecha esperada de titulación</Error>;
  const tutorNameError = errors.tutor && <Error>Por favor, indica el nombre del tutor</Error>;
  const tutorLastNameError = errors.lastname && <Error>Por favor, indica los apellidos del tutor</Error>;
  const tutorEmailError = errors.email?.type === 'required' && <Error>Por favor, indica el e-mail del tutor</Error>;
  const tutorEmailError2 = errors.email?.type === 'pattern' && <Error>Formato de email incorrecto</Error>;
  const tutorTelfError = errors.telf?.type === 'required' && <Error>Por favor, indica el teléfono del tutor</Error>;
  const tutorTelfError2 = errors.telf?.type === 'pattern' && <Error>Número de teléfono no válido</Error>;

  useEffect(() => {
    reset({
      country: initialCountry,
      iniDate,
      endDate,
      gradDate,
      institutionName: institutionName || universityName,
    });
  }, [reset, profile]);

  /* set the form to active/inactive to be edited */
  const [cvFormActive, setCvFormActive] = useState(false);
  const [selectedCardStudy, setSelectedCardStudy] = useState(null);

  const cardRefStudies = useRef(`study-${name + institutionName}`);

  useEffect(() => {
    const currentStudies = _get(profile, 'currentStudies', []);
    if (currentStudies === null) {
      setCvFormActive(true);
      setSelectedCardStudy(cardRefStudies.current);
    }
  }, [profile]);

  const openByRef = true;

  const edit = (e) => {
    e.preventDefault();
    setCvFormActive(!cvFormActive);
    setSelectedCardStudy(cardRefStudies.current);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    setCvFormActive(!cvFormActive);

    const newStudent = {
      currentStudy: {
        studyCountryId: getId(residences, data.country),
        city: data.city,
        address: data.address,
        cp: data.cp,
        region: data.region,
        campus: data.campus,
        endDate: data.endDate,
        gradDate: data.gradDate,
        ended: currentStudyEnded,
        name: data.name,
        institutionName: data.institutionName,
      },
      tutor: {
        name: data.tutor,
        lastname: data.lastname,
        email: data.email,
        phone: data.telf,
      },
    };
    if (data.iniDate) newStudent.currentStudy.iniDate = data.iniDate;
    dispatch(studentActions.save(newStudent));
  };

  /* delete current studies button */
  const deleteCurrentStudy = () => {
    setCvFormActive(!cvFormActive);

    dispatch(studentActions.save({ currentStudy: { ended: currentStudyEnded }, tutor: {} }));
  };

  const tutorRequiredCharacter = currentStudyEnded ? '' : '*';

  return (
    <>
      <MobilePadding>
        <FormTitle
          title={littleDevice ? 'ÚLTIMA FORMACIÓN ACADÉMICA*:' : 'ESTUDIOS EN CURSO*:'}
          onClick={edit}
        />
      </MobilePadding>

      <ContactForm key={name + institutionName} onSubmit={handleSubmit(onSubmit)}>

        <WhiteSpace height="1rem" />

        <MobilePadding>
          <FullFlexCont mobilePadding="0">
            <InputColumn width="fit-content">

              <BlueInput
                bold
                width="300px"
                mBottom="0"
                disabled={!cvFormActive}
                name="name"
                placeholder="Nombre de tu carrera*"
                ref={register({ required: true })}
                defaultValue={name}
              />
              {nameError}

              <BlueInput
                width="300px"
                disabled={!cvFormActive}
                name="institutionName"
                placeholder="Nombre del centro*"
                ref={register({ required: true })}
                defaultValue={institutionName}
              />
              {institutionError}
            </InputColumn>

            {!littleDevice && (
              <EditDeleteIconsCont>
                <EditDeleteIcons
                  edit={edit}
                  deleteOne={deleteCurrentStudy}
                  isActive={cvFormActive}
                  onSubmit={onSubmit}
                  confirmText="¿Estás seguro de que quieres eliminar esta ficha?"
                />
              </EditDeleteIconsCont>
            )}

          </FullFlexCont>
        </MobilePadding>

        <SeeMoreCard
          openByRef={openByRef}
          selectedCard={selectedCardStudy}
          profile={profile}
          setSelectedCard={setSelectedCardStudy}
        >

          <WhiteSpace height="4rem" />

          <FullFlexCont mobilePadding="0">
            <Label>Dirección institución académica*</Label>
          </FullFlexCont>

          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">País*:</SubLabel>
              <Select
                disabled={!cvFormActive}
                name="country"
                ref={register({ required: true })}
                mobileMargin="0"
                mobileWidth="50%"
              >
                {showOptions(residences, initialCountry, 'nombre país')}
              </Select>

              {countryError}
            </InputColumn>
          </Column>
          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">Ciudad*:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="city"
                placeholder="ciudad"
                ref={register({ required: true })}
                defaultValue={city}
                mobileMargin="0"
                mobileWidth="50%"
              />
              {cityError}
            </InputColumn>
          </Column>
          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">Calle y nº:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="address"
                placeholder="dirección de la institución"
                ref={register({ required: false })}
                defaultValue={address}
                mobileMargin="0"
                mobileWidth="50%"
              />
            </InputColumn>
          </Column>

          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">Código Postal:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="cp"
                placeholder="código postal"
                ref={register({ required: false })}
                defaultValue={cp}
                mobileMargin="0"
                mobileWidth="50%"
              />
            </InputColumn>
          </Column>
          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">Estado/región:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="region"
                placeholder="estado / región"
                ref={register({ required: false })}
                defaultValue={region}
                mobileMargin="0"
                mobileWidth="50%"
              />
            </InputColumn>
          </Column>
          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn mobileRow>
              <SubLabel mobileWidth="50%">Campus:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="campus"
                placeholder="campus"
                ref={register({ required: false })}
                defaultValue={campus}
                mobileMargin="0"
                mobileWidth="50%"
              />
            </InputColumn>
          </Column>

          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <Label>Fecha inicio:</Label>
              {
                cvFormActive ? (
                  <Controller
                    name="iniDate"
                    control={control}
                    render={() => (
                      <DatePicker
                        setValue={setValue}
                        name="iniDate"
                        value={watchFields.iniDate}
                        onValueChange={(value) => setValue('iniDate', value)}
                      />
                    )}
                  />
                ) : <span>{dates.getStringDate(iniDate) || 'dd/mm/yyyy'}</span>
              }
            </InputColumn>
          </Column>
          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <Label>Fecha fin estudios*:</Label>
              {
                cvFormActive ? (
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{ required: true }}
                    render={() => (
                      <DatePicker
                        setValue={setValue}
                        name="endDate"
                        value={watchFields.endDate}
                        onValueChange={(value) => setValue('endDate', value)}
                      />
                    )}
                  />
                ) : <span>{dates.getStringDate(endDate) || 'dd/mm/yyyy'}</span>
              }
              {endDateError}
            </InputColumn>
          </Column>
          <Column width={30} padding="0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <Label>Año titulación*:</Label>
              <Input
                disabled={!cvFormActive}
                name="gradDate"
                placeholder="año de la titulación"
                ref={register({ required: true })}
                defaultValue={campus}
                mobileMargin="0"
                mobileWidth="50%"
              />
              {gradDateError}
            </InputColumn>
          </Column>

          <FullFlexCont mobilePadding="0">
            <Label>Tutor de prácticas{tutorRequiredCharacter}:</Label>
          </FullFlexCont>

          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <SubLabel>Nombre{tutorRequiredCharacter}:</SubLabel>
              <Input
                mBottom="0"
                disabled={!cvFormActive}
                name="tutor"
                placeholder="nombre del tutor"
                ref={register({ required: !currentStudyEnded })}
                defaultValue={tutor}
                mobileMargin="0"
              />
              {tutorNameError}
              <Input
                disabled={!cvFormActive}
                name="lastname"
                placeholder="apellidos del tutor"
                ref={register({ required: !currentStudyEnded })}
                defaultValue={lastname}
                mobileMargin="0"
              />
              {tutorLastNameError}
            </InputColumn>
          </Column>
          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <SubLabel>e-mail{tutorRequiredCharacter}:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="email"
                placeholder="email del tutor"
                ref={register({
                  required: !currentStudyEnded,
                  pattern: {
                    value: /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/,
                  },
                })}
                defaultValue={email}
                mobileMargin="0"
              />
              {tutorEmailError}
              {tutorEmailError2}
            </InputColumn>
          </Column>
          <Column width={30} padding="1rem 2rem 0 2rem" margin="0" center mobileFull mobileMargin="1rem 0">
            <InputColumn>
              <SubLabel>Telf{tutorRequiredCharacter}:</SubLabel>
              <Input
                disabled={!cvFormActive}
                name="telf"
                placeholder="teléfono del tutor"
                ref={register({
                  required: !currentStudyEnded,
                  pattern: {
                    value: /^\+[0-9][0-9]{6,20}$/,
                  },
                })}
                defaultValue={phone}
                mobileMargin="0"
              />
              {tutorTelfError}
              {tutorTelfError2}
            </InputColumn>
          </Column>

          {cvFormActive
            && (
              <>
                <HSpacer height="1.5rem" />
                <ButtonPrimary
                  type={3}
                  text="Guardar"
                  htmlType="submit"
                  onClick={Object.keys(errors).length === 0 ? scrollToTop : null}
                  margin="0 0 0 auto"
                />
              </>
            )}

        </SeeMoreCard>

      </ContactForm>
    </>
  );
};

export default CurrentStudy;
